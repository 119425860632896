import {Component, ViewChild} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {BookService, ListFilters} from '../book.service';
import {Book} from '../types/book.type';
import {Page} from '../types/page.type';
import {faPen, faTrash} from '@fortawesome/free-solid-svg-icons';
import {IconDefinition} from '@fortawesome/fontawesome-common-types';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {ConfirmDialogComponent} from '../confirmDialog/confirmDialog.component';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {EditPopupComponent} from '../editpopup/edit-popup.component';


@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent {

  displayedColumns =
    ['title', 'author', 'schlagwort', 'erscheinungsjahr', 'kommentar', 'edit', 'delete'];


  faTrash: IconDefinition = faTrash;
  faPencil: IconDefinition = faPen;

  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;

  angForm = new FormGroup({
    filter: new FormControl(''),
    type: new FormControl('')
  });

  books: Page<Book> = {
    content: [],
    totalElements: 0,
    totalPages: 0,
  };
  private filters: ListFilters = {};
  page = 1;

  get filter(): any {
    return this.angForm.get('filter');
  }

  get type(): any {
    return this.angForm.get('type');
  }

  constructor(private bookService: BookService, private dialog: MatDialog, private snackBar: MatSnackBar) {
    this.loadData();
    this.angForm.get('filter').valueChanges.subscribe((value: string) => {
      this.filters.search = value;
      this.loadData(this.filters);
    });

    this.angForm.get('type').valueChanges.subscribe((value: string) => {
      this.filters.type = value;
      this.loadData(this.filters);
    });
  }

  private loadData(filter?: ListFilters) {
    this.bookService.getListOfBooks(filter).subscribe(data => {
      this.books = data;
    });
  }

  resetSearch = () => {
    this.filters = {};
    this.angForm.reset();
    this.loadData();
  }

  loadNewPage = ($event: PageEvent) => {
    this.filters.page = this.paginator.pageIndex;
    this.loadData(this.filters);
  }

  deleteBook = (book: Book) => {
    const message = `Soll das Buch <b>${book.title}</b> von <b>${book.author}</b> wirklich gelöscht werden?`;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '550px',
      data: message
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.bookService.deleteItemById(book.id).subscribe((response) => {
          this.snackBar.open(response.message, '', {
            duration: 2000,
          });
          this.loadData(this.filter);
        });
      }
    });
  }

  updateItem = (item: Book) => {
    const dialogRef = this.dialog.open(EditPopupComponent, {
      width: '500px',
      data: item
    });

    return dialogRef.afterClosed().subscribe(result => {
      if (result) {
        return this.bookService.editBook(result).subscribe((response) => {
          this.snackBar.open(response.message);
          this.loadData(this.filter);
        });
      }
    });
  }


}
