import {Component, OnDestroy, OnInit} from '@angular/core';
import {DecimalPipe} from '@angular/common';
import {BookService} from '../book.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {OpenBookApi} from './bookApi.type';
import {TypeEnum} from '../types/book.type';
import {ActivatedRoute} from '@angular/router';


@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  providers: [DecimalPipe]
})
export class CreateComponent implements OnInit, OnDestroy {

  searchForm: FormGroup;
  book: FormGroup;
  foundBooks: Partial<OpenBookApi>;

  allTypes: string[] = Object.keys(TypeEnum);

  private sub: any;
  private id: string;

  get isbnSearch(): any {
    return this.searchForm.get('isbnSearch');
  }

  constructor(private bookService: BookService, private route: ActivatedRoute, private formBuilder: FormBuilder) {
  }

  ngOnInit(): void {
    this.searchForm = this.formBuilder.group({
      isbnSearch: [],
    });
    this.book = this.formBuilder.group({
      id: ['', []],
      isbn: ['', [Validators.required]],
      title: ['', [Validators.required]],
      author: ['', [Validators.required]],
      erscheinungsjahr: ['', [Validators.required]],
      type: ['', [Validators.required]],
      schlagwort: ['', [Validators.required]],
    });
    this.sub = this.route.params.subscribe(params => {
      this.id = params.id;
      if (this.id) {
        this.bookService.findById(this.id).subscribe((bookData) => {
          this.foundBooks = {title: 'dummy'};
          this.book.controls.id.setValue(bookData.id);
          this.book.controls.isbn.setValue(bookData.isbn);
          this.book.controls.title.setValue(bookData.title);
          this.book.controls.author.setValue(bookData.author);
          this.book.controls.erscheinungsjahr.setValue(bookData.erscheinungsjahr);
          this.book.controls.type.setValue(bookData.type);
          this.book.controls.schlagwort.setValue(bookData.schlagwort);
        });
      }
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }


  searchForISBN = () => {
    const isbn = this.isbnSearch.value;
    this.bookService.findByISBN(isbn).subscribe(response => {
      const objectKeys = Object.keys(response);
      if (objectKeys.length === 0) {
        this.foundBooks = {};
        return;
      }
      this.foundBooks = response[objectKeys[0]];

      this.book.controls.isbn.setValue(isbn);
      this.book.controls.title.setValue(this.foundBooks.title);
      this.book.controls.author.setValue(this.foundBooks.authors.map(e => e.name).join(', '));
      this.book.controls.erscheinungsjahr.setValue(this.foundBooks.publish_date);
      this.book.controls.type.setValue([TypeEnum.Freizeit]);
      this.book.controls.schlagwort.setValue('');
    });
  }

  submitBook() {
    if (this.book.controls.id.value) {
      alert('Update Book');
    } else {
      this.book.removeControl('id');
      this.bookService.createBook(this.book.value).subscribe(response => {
        this.isbnSearch.value = '';
        this.foundBooks = null;
      });
    }

  }
}
