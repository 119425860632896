<h1 mat-dialog-title>Buch bearbeiten</h1>
<div mat-dialog-content [formGroup]="form" fxLayout="column">
  <mat-form-field>
    <mat-label>ISBN</mat-label>
    <input matInput type="text" formControlName="isbn"/>
  </mat-form-field>

  <div fxLayout="row">
    <mat-form-field fxFlex>
      <mat-label>Autor</mat-label>
      <input matInput type="text" formControlName="author"/>
    </mat-form-field>
    <mat-form-field fxFlex fxFlexOffset="5">
      <mat-label>Titel</mat-label>
      <input matInput type="text" formControlName="title"/>
    </mat-form-field>
  </div>
  <div fxLayout="row">
    <mat-form-field fxFlex>
      <mat-label> Erscheinungsjahr</mat-label>
      <input matInput type="number" formControlName="erscheinungsjahr"/>
    </mat-form-field>
    <mat-form-field fxFlex fxFlexOffset="5">
      <mat-label>Thema</mat-label>
      <mat-select [formControlName]="'type'" multiple>
        <mat-option *ngFor="let type of allTypes" [value]="type">{{type}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <mat-form-field fxFlex="55">
    <mat-label>Schlagwörter</mat-label>
    <input matInput type="text" formControlName="schlagwort"/>
  </mat-form-field>

  <mat-form-field fxFlex="55">
    <mat-label>Kommentar</mat-label>
    <textarea matInput formControlName="kommentar"></textarea>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button color="primary" mat-raised-button (click)="save()" [disabled]="form.invalid">Speichern</button>
  <button mat-button color="warn" (click)="close()">Abbrechen</button>
</div>
