export interface Book {
  id: string;
  isbn: string;
  title: string;
  author: string;
  erscheinungsjahr: number;
  type: TypeEnum[];
  schlagwort: string;
  kommentar: string;
}

export enum TypeEnum {
  Deutsch = 'Deutsch', Psychologie = 'Psychologie', Freizeit = 'Freizeit', Bildungswissenschaften = 'Bildungswissenschaften'
}
