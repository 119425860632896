<div style="height: 100%">
  <form [formGroup]="angForm">
    <div class="form-group form-inline">
      <mat-form-field>
        <mat-label>Suche</mat-label>
        <input matInput type="text" formControlName="filter"/>
      </mat-form-field>

      <mat-radio-group style="margin-left: 2%" aria-label="Wähle eine Kategorie" formControlName="type">
        <mat-radio-button style="margin-left: 1%" value="Freizeit">Freizeit</mat-radio-button>
        <mat-radio-button style="margin-left: 1%" value="Psychologie">Psychologie</mat-radio-button>
        <mat-radio-button style="margin-left: 1%" value="Deutsch">Deutsch</mat-radio-button>
        <mat-radio-button style="margin-left: 1%" value="Bildungswissenschaften">Bildungswissenschaften
        </mat-radio-button>
      </mat-radio-group>
      <button style="margin-left: 5%" mat-raised-button color="primary" (click)="resetSearch()">Suche Zurücksetzen
      </button>
    </div>
  </form>
  <div class="table">
    <table mat-table [dataSource]="books.content">
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef>Title</th>
        <td mat-cell *matCellDef="let element"> {{element.title}} </td>
      </ng-container>
      <ng-container matColumnDef="author">
        <th mat-header-cell *matHeaderCellDef> Author</th>
        <td mat-cell *matCellDef="let element"> {{element.author}} </td>
      </ng-container>
      <ng-container matColumnDef="schlagwort">
        <th mat-header-cell *matHeaderCellDef> Schlagwörter</th>
        <td mat-cell *matCellDef="let element"> {{element.schlagwort}} </td>
      </ng-container>
      <ng-container matColumnDef="erscheinungsjahr">
        <th mat-header-cell *matHeaderCellDef> Erscheinungsjahr</th>
        <td mat-cell *matCellDef="let element"> {{element.erscheinungsjahr}} </td>
      </ng-container>kommentar
      <ng-container matColumnDef="kommentar">
        <th mat-header-cell *matHeaderCellDef> Kommentar</th>
        <td mat-cell *matCellDef="let element"> {{element.kommentar}} </td>
      </ng-container>
      <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef>
          Bearbeiten
        </th>
        <td mat-cell *matCellDef="let element">
          <a mat-raised-button color="primary" (click)="updateItem(element)">
            <fa-icon [icon]="faPencil"></fa-icon>
          </a></td>
      </ng-container>
      <ng-container matColumnDef="delete">
        <th mat-header-cell *matHeaderCellDef>
          Löschen
        </th>
        <td mat-cell *matCellDef="let element">
          <button mat-raised-button color="warn" (click)="deleteBook(element)">
            <fa-icon [icon]="faTrash"></fa-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  <mat-paginator [length]="books.totalElements"
                 [pageSize]="50"
                 (page)="loadNewPage($event)">
  </mat-paginator>
</div>
