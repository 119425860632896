<div fxLayout="column" fxFlex="80" fxFlexOffset="10">
  <h1>Buch hinzufügen</h1>
  <form [formGroup]="searchForm" fxFlex="50">
    <mat-form-field fxFlex="70">
      <mat-label>ISBN</mat-label>
      <input matInput type="text" id="isbnSearch" formControlName="isbnSearch"/>
    </mat-form-field>
    <button fxFlexOffset="5" mat-raised-button color="primary" (click)="searchForISBN()">Buch suchen</button>
  </form>

  <div *ngIf="foundBooks">
    <h1>Buch eintragen</h1>
    <form [formGroup]="book" fxLayout="column">
      <mat-form-field>
        <label>ISBN</label>
        <input matInput type="text" formControlName="isbn"/>
      </mat-form-field>

      <div fxLayout="row">
        <mat-form-field fxFlex>
          <label>Autor</label>
          <input matInput type="text" formControlName="author"/>
        </mat-form-field>
        <mat-form-field fxFlex fxFlexOffset="5">
          <label>Titel</label>
          <input matInput type="text" formControlName="title"/>
        </mat-form-field>
      </div>
      <div fxLayout="row">
        <mat-form-field fxFlex>
          <label> Erscheinungsjahr</label>
          <input matInput type="number" formControlName="erscheinungsjahr"/>
        </mat-form-field>
        <mat-form-field fxFlex fxFlexOffset="5">
          <mat-label>Thema</mat-label>
          <mat-select [formControlName]="'type'" multiple>
            <mat-option *ngFor="let type of allTypes" [value]="type">{{type}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <mat-form-field fxFlex="55">
        <label>Schlagwörter</label>
        <input matInput type="text" formControlName="schlagwort"/>
      </mat-form-field>
      <button mat-raised-button color="primary" (click)="submitBook()">Buch speichern</button>
    </form>
  </div>
</div>
