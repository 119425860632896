import {Component, Inject} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Book, TypeEnum} from '../types/book.type';

interface UploadArbeit {
  themen: string;
  filename: File;
  fach: string;
  klasse: string;
  modifiedAt: string;
}

@Component({
  selector: 'app-edit-popup',
  templateUrl: './edit-popup.component.html'
})
export class EditPopupComponent {

  form: FormGroup;
  allTypes: string[] = Object.keys(TypeEnum);

  constructor(private fb: FormBuilder,
              public dialogRef: MatDialogRef<EditPopupComponent>,
              @Inject(MAT_DIALOG_DATA) public data: Partial<Book>) {

    this.form = this.fb.group({
      id: ['', []],
      isbn: ['', [Validators.required]],
      title: ['', [Validators.required]],
      author: ['', [Validators.required]],
      erscheinungsjahr: ['', [Validators.required]],
      type: ['', [Validators.required]],
      schlagwort: ['', [Validators.required]],
      kommentar: ['', []],
    });

    if (data !== null) {
      this.form.controls.id.setValue(data.id);
      this.form.controls.isbn.setValue(data.isbn);
      this.form.controls.title.setValue(data.title);
      this.form.controls.author.setValue(data.author);
      this.form.controls.erscheinungsjahr.setValue(data.erscheinungsjahr);
      this.form.controls.type.setValue(data.type);
      this.form.controls.schlagwort.setValue(data.schlagwort);
      this.form.controls.kommentar.setValue(data.kommentar);
    }
  }

  save() {
    this.dialogRef.close(this.form.value);
  }

  close() {
    this.dialogRef.close();
  }
}
