import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {ListComponent} from './list/list.component';
import {ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {CreateComponent} from './create/create.component';
import {RouterModule, Routes} from '@angular/router';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatTableModule} from '@angular/material/table';
import {MatSelectModule} from '@angular/material/select';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatInputModule} from '@angular/material/input';
import {MatRadioModule} from '@angular/material/radio';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatSortModule} from '@angular/material/sort';
import {ConfirmDialogComponent} from './confirmDialog/confirmDialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {EditPopupComponent} from './editpopup/edit-popup.component';

const appRoutes: Routes = [
  {path: 'list', component: ListComponent},
  {path: 'edit/:id', component: CreateComponent},
  {path: 'create', component: CreateComponent},
  {path: '**', redirectTo: 'list'}
];


@NgModule({
  declarations: [
    AppComponent,
    ListComponent,
    CreateComponent,
    ConfirmDialogComponent,
    EditPopupComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    RouterModule.forRoot(
      appRoutes
    ),
    HttpClientModule,
    FontAwesomeModule,
    BrowserAnimationsModule,
    MatTableModule,
    MatSelectModule,
    MatToolbarModule,
    MatButtonModule,
    MatPaginatorModule,
    MatInputModule,
    MatRadioModule,
    MatSortModule,
    MatDialogModule,
    MatSnackBarModule,
    FlexLayoutModule
  ],
  entryComponents: [
    ConfirmDialogComponent,
    EditPopupComponent
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {

}
