import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Book} from './types/book.type';
import {OpenBookApi} from './create/bookApi.type';
import {Page} from './types/page.type';


declare type ListFilterValue = number | string[] | string;


interface ListFilter {
  key: string;
  value: ListFilterValue;
}


export interface ListFilters {
  [key: string]: ListFilterValue;
}

@Injectable({
  providedIn: 'root',
})
export class BookService {

  private static BASE_URL = 'books';

  constructor(private httpClient: HttpClient) {
  }

  getListOfBooks = (filters: ListFilters) => {
    const params = this.createQuery(filters);
    return this.httpClient.get<Page<Book>>(BookService.BASE_URL, {params});
  }

  private createQuery = (filters: ListFilters): {
    [param: string]: string | string[];
  } => {
    const filterQuery: any = {};
    for (const key in filters) {
      if (filters.hasOwnProperty(key)) {
        if (filters[key] != null) {
          filterQuery[key] = filters[key];
        }
      }
    }
    return filterQuery;
  }

  findByISBN = (isbn: string) => {
    return this.httpClient.get<OpenBookApi>(`https://openlibrary.org/api/books?bibkeys=ISBN:${isbn}&format=json&jscmd=data`);
  }

  createBook(newBook: Partial<Book>) {
    return this.httpClient.post(BookService.BASE_URL, newBook);
  }

  editBook(newBook: Partial<Book>) {
    return this.httpClient.put<{ message: string }>(BookService.BASE_URL, newBook);
  }

  findById = (id: string) => {
    return this.httpClient.get<Book>(`${BookService.BASE_URL}/${id}`);
  }

  deleteItemById(id: string) {
    return this.httpClient.delete<{ message: string }>(`${BookService.BASE_URL}/${id}`);
  }

}
